import {headerAnimation} from "../animations/components/headerAnimation";
import planetEarth from "../animations/components/planetEarth";
import {stepIn,stepOut,triggerController} from "../animations/components/campignSteps";

const creative = _ => {
	gsap.config({
		nullTargetWarn: false
	});
	headerAnimation()
	planetEarth()

	let steps_section_tl = gsap.timeline();
	steps_section_tl
		.add(stepOut(1, 1, 1, true))
		.add(stepIn(2, 2, true, true), '-=0.4');

		window.innerWidth <= 1000 ? triggerController('1500', steps_section_tl) :	triggerController('2500', steps_section_tl)
}


export default creative;