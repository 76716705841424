

const menu = _ => {
    
// Common JS scripts
let open_menu = document.getElementById('menu-btn');
let full_menu = document.querySelector('.full-menu');
let navigation = document.querySelector('.navigation');
let full_menu_items = document.querySelectorAll('.navigation .full-menu_block ul li a');
document.querySelectorAll('.menu-btn').forEach(btn => {
	btn.addEventListener('click', e => {
		btn.classList.toggle('active');
	});
});
open_menu.addEventListener('click', function () {
	for (let i = 0; i < full_menu_items.length; i++) {
		full_menu_items[i].addEventListener('click', function () {
			full_menu.classList.remove('menu-opened');
		})
	}
	full_menu.classList.toggle('menu-opened');
	navigation.classList.toggle('menu-is-opened')
});

}

export default menu