const showcase = _ =>{
    	// ******************  START CATALYTICS Animation
	let showcase_tl = gsap.timeline();
	showcase_tl.from('.showcase  .heading-xl ', 1.5, {
			clipPath: 'inset(200% -1% -1% -1%)',
			y: -200
		})
		// .to('.showcase-bg',0.5,{y:'-10rem'})
		.from('.showcase .pargaraph', 0.5, {
			x: '15rem'
		}, '-=1.2')
	ScrollTrigger.create({
		animation: showcase_tl,
		trigger: '.showcase',
		start: 'top +=80%',
		end: '+=60% +=70%',
		pin: false,
		markers: false,
		scrub: 0.1,
	})
}

export default showcase;