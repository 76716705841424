
 const getOS = _ => {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        // macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        // windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;

    if (iosPlatforms.indexOf(platform) !== -1) {
        var root = document.getElementsByTagName('html')[0];
        root.classList.add('IOS_Phone')
    }
    return os;
}


export default getOS;