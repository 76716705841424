import Swiper from './../../../node_modules/swiper/swiper-bundle';
import $ from 'jquery';
export default function news_section() {

 

  var swiper = new Swiper(".swiper-news-1", {
    spaceBetween: 30,
    centeredSlides: true,
    slidesPerView: 6,
    speed: 2000,
    direction: "vertical",
    loop: true,
    autoplay: {
      delay: false,
      disableOnInteraction: true,
      freeModeMomentum: false
    },
    breakpoints:{
      200:{
        slidesPerView: 3,
        spaceBetween:50
      },
      896:{
        slidesPerView: 6,
        spaceBetween: 30,
      }
    }
 
  });
  var swiper_1 = new Swiper(".swiper-news-2", {
    spaceBetween: 30,
    slidesPerView: 6,
    speed: 2000,
    direction: "vertical",
    loop: true,
    autoplay: {
      delay: false,
      disableOnInteraction: true,
    },
    breakpoints:{
      200:{
        slidesPerView: 3,
        spaceBetween:50
      },
      896:{
        slidesPerView: 6,
        spaceBetween: 30,
      }
    }

  });
  var swiper_2 = new Swiper(".swiper-news-3", {
    spaceBetween: 30,
    speed: 2000,
    slidesPerView: 6,
    direction: "vertical",
    loop: true,
    autoplay: {
      delay: false,
      disableOnInteraction: true,
    },
    breakpoints:{
      200:{
        slidesPerView: 3,
        spaceBetween:50
      },
      896:{
        slidesPerView: 6,
        spaceBetween: 30,
      }
    }
    
  });
  var swiper_3 = new Swiper(".swiper-news-4", {
    spaceBetween: 30,
    slidesPerView: 6,
    speed: 2000,
    direction: "vertical",
    loop: true,
    autoplay: {
      delay: false,
      disableOnInteraction: true,
    },
    breakpoints:{
      200:{
        slidesPerView: 3,
        spaceBetween:50
      },
      896:{
        slidesPerView: 6,
        spaceBetween: 30,
      }
    }

  });

  $('.swiper-news-1').on('mouseenter', function (e) {
    swiper.autoplay.stop();
  })
  $('.swiper-news-1').on('mouseleave', function (e) {
    swiper.autoplay.start();
  })

  $('.swiper-news-2').on('mouseenter', function (e) {
    swiper_1.autoplay.stop();
  })
  $('.swiper-news-2').on('mouseleave', function (e) {
    swiper_1.autoplay.start();
  })

  $('.swiper-news-3').on('mouseenter', function (e) {
    swiper_2.autoplay.stop();
  })
  $('.swiper-news-3').on('mouseleave', function (e) {
    swiper_2.autoplay.start();
  })

  $('.swiper-news-4').on('mouseenter', function (e) {
    swiper_3.autoplay.stop();
  })
  $('.swiper-news-4').on('mouseleave', function (e) {
    swiper_3.autoplay.start();
  })
}