const pitch_deck = () => {
  // Pages has no scribts you can put it here with class name
  // let download_submit = document.getElementById('download_submit');
  // download_submit.addEventListener('click',function(){
  //     setTimeout(() => {
  // 		document.getElementById("download_pdf_form").submit();
  // 	}, 2000);
  // })
  document.addEventListener('DOMContentLoaded', function () {
    setTimeout(() => {
      document.querySelector(".css-q2ejqt").setAttribute('placeholder','Email')
    }, 2500);
    // document.querySelector('.css-q2ejqt').setAttribute('placeholder','$');
  });


}

export default pitch_deck;