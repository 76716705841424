import {headerAnimation} from "../animations/components/headerAnimation";
import {stepIn, stepOut,triggerController} from "../animations/components/campignSteps";

const manage = _ => {

	gsap.config({
		nullTargetWarn: false
	});

	headerAnimation()

	let steps_section_tl = gsap.timeline();
	steps_section_tl
		.add(stepOut(1, 1, 1, false))
		.add(stepIn(2, 2, false), '-=0.2')

		.add(stepOut(2, 2, 2, false))
		.add(stepIn(3, 3, false), '-=0.2')

		.add(stepOut(3, 3, 3, false))
		.add(stepIn(4, 4, false), '-=0.2')


		window.innerWidth <= 1000 ? triggerController('1500', steps_section_tl) : triggerController('4500', steps_section_tl)
	
	steps_section_tl.to('.campaign_steps--text .planet_text_block .btn-tertiery', 0.3, {
		y: '3rem',
		opacity: 1,
		display: 'flex'
	}, '-=0.3')


}

export default manage;