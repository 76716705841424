import getOS from '../utilities/getOS';
import choosePlan from '../utilities/choosePlan'
import './../crypto-js';
import { demoRequestForm } from '../components/forms';
import {aboutSection, generalAnimations} from '../animations/pages/home';
import Swiper from './../../../node_modules/swiper/swiper-bundle';
import news_section from '../components/news_section';

const homepage = _ =>{

// Call News sections sliders
news_section()



	let header_bg_video = document.querySelector("#header_bg_video");
	let bg_video_section = document.querySelector('.bg-video');
    header_bg_video.addEventListener("error", function(e) {
		bg_video_section.innerHTML = '<img src="./assets/images/video_placholder.png"/>'
  });

	// To stop Animation while foucs over the Build your campaign input
	// var email_campaign__input = document.getElementById('email_campaign__input');
	// email_campaign__input.addEventListener('focusin', () => document.querySelector('.header__text-box').classList.add('force_show'));
	// email_campaign__input.addEventListener('focusout', () => document.querySelector('.header__text-box').classList.remove('force_show'));



    

    // Calling get OS to add some CSS stuff on all IOS devices
    getOS();
    // Calling ChoosePlan this for get selected card and send to FORM
    choosePlan();
    // The Header Form for Crypto
    // contact form
    demoRequestForm()
    // -------------------------------------------

    // Call animations

    // About section
    aboutSection()
    generalAnimations()





      //initialize swiper when document ready  
      var mySwiper = new Swiper ('.swiper-container', {
        autoplay: true,
        loop: false,
        parallax: false,
        responsive:true,
        pagination: '.swiper-pagination',
        paginationClickable: true,
        grabCursor: true,
        speed:1500,
        
        onTransitionStart(mySwiper) {
          if ($(".swiper-slide-active").hasClass("Car--riva")) {
            $("body").css("background-color", "#6D889F");
          } else {
            $("body").css("background-color", "#E74824");
          }
        }
      }); 

}

export default homepage