let steps_boxEle = document.querySelector('.campaign_steps .overflow-h-box');
let steps_box,steps_boxNumber;


if ( document.body.classList.contains('creative_page') || document.body.classList.contains('manage_page') || document.body.classList.contains('platform_page')) {
    
    if(steps_box == null){
        steps_box = getComputedStyle(steps_boxEle).getPropertyValue('height');
        steps_boxNumber = parseInt(steps_box);
        window.innerWidth <= 500 ? steps_boxNumber = (steps_boxNumber - 27) : null;
    }

}


if (document.body.classList.contains('manage_page')) {
    // this to calculate the height of the text in every page to change size of overflowboxs
    steps_boxNumber += 7.5;
}


const stepOut = (imgNumber, descNumber, numStep, hasImg, hasVideo = false) => {
    // This function to make all elements in steps section OUT
    let tl = gsap.timeline();
    tl
    if (hasImg == true) {
        tl.to(`.campaign_steps .overflow-h-box--images img:nth-child(${imgNumber})`, 0.2, {
            opacity: 0,
            display: 'none'
        })
    }
    tl.to(`.overflow-h-box--descriptions h3:nth-child(${descNumber})`, 0.2, {
            opacity: 0
        }, '-=0.25')
        .to('.campaign_steps .cs_animation_content', 0.3, {
            y: `${-steps_boxNumber * numStep}`
        }, '-=0.15')
    return tl
}



const stepIn = (imgNumber, descNumber, hasImg, hasVideo = false) => {
    // This function to make all elements in steps section IN
    
    let tl = gsap.timeline();
    if (hasImg == true) {
        tl
            .to(`.campaign_steps .overflow-h-box--images img:nth-child(${imgNumber})`, 0.2, {
                opacity: 1,
                display: 'block'
            })
    }
    if (hasVideo == true) {
        tl
            .fromTo(`.campaign_steps .overflow-h-box--images video`, 0.2, {
                opacity: 0,
                display: 'none',

            }, {
                opacity: 1,
                display: 'block',
                onStart: (e) => {
                    let videoStep = document.querySelector('.campaign_steps .overflow-h-box--images video');
                    videoStep.play()
                },
                onComplete: () => {
                    let videoStep = document.querySelector('.campaign_steps .overflow-h-box--images video');
                    videoStep.currentTime = 0;
                }
            })
    }


    tl
        .to(`.overflow-h-box--descriptions h3:nth-child(${descNumber})`, 0.2, {
            opacity: 1
        }, '-=0.18')
    return tl
}
const triggerController = (endValue, triggerTL) => {
    ScrollTrigger.create({
        animation: triggerTL,
        trigger: ".campaign_steps",
        start: "top top",
        end: `+=${endValue}`,
        pin: true,
        markers: false,
        scrub: 0.1,

    });
}


export {
    stepIn,
    stepOut,
    triggerController
}