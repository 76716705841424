import { headerAnimation } from "../animations/components/headerAnimation";
import planetEarth from "../animations/components/planetEarth";
import {stepIn,stepOut,triggerController} from "../animations/components/campignSteps";


const platform = _ => {
    gsap.config({
		nullTargetWarn: false
	});

	// Header animations 
    headerAnimation();

	// The planet earth section animation
    planetEarth();

	let steps_section_tl = gsap.timeline();
	steps_section_tl
	
	.add(stepOut(1, 1, 1, true))
	.add(stepIn(2, 2, true), '-=0.2')

	.add(stepOut(2, 2, 2, true))
	.add(stepIn(3, 3, true), '-=0.2')

	.add(stepOut(3, 3, 3, true))
	.add(stepIn(4, 4, true), '-=0.2')

	.add(stepOut(4, 4, 4, true))
	.add(stepIn(5, 5, true), '-=0.2')

	window.innerWidth <= 1000 ? triggerController('1500', steps_section_tl) :	triggerController('4500', steps_section_tl)
}


export default platform;