import menu from './components/menu';

// import pages
import homepage from './pages/homepage';
import creative from './pages/creative';
import platform from './pages/platform';
import manage from './pages/manage';
import faq from './pages/faq';
import contact from './pages/contact';
import pitch_deck from './pages/pitch-deck';
import easiest_awareness from './pages/easiest_awareness';


// Animations for MENU
if (document.body.classList.contains('pitch-deck')) {} 
else {
	// This code is globale for all pages except the pitch-deck page
	menu();
	gsap.registerPlugin(ScrollTrigger);
	document.addEventListener("DOMContentLoaded", function () {
		if (window.location.hash) {
			var hash = window.location.hash.substr(1);
			var target = $('#' + hash);
			$('html, body').stop().animate({
				'scrollTop': target.offset().top
			});
		}
	});
}

document.body.classList.contains('homepage') ? homepage() : null;
document.body.classList.contains('platform_page') ? platform() : null;
document.body.classList.contains('creative_page') ? creative() : null;
document.body.classList.contains('manage_page') ? manage() : null;
document.body.classList.contains('faq_page') ? faq() : null;
document.body.classList.contains('contactus_page') ? contact() : null;
document.body.classList.contains('easiest_awareness') ? easiest_awareness() : null;
document.body.classList.contains('pitch-deck') ? pitch_deck() : null;