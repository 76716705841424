const campaignSetup =_=>{
    let the_process_section_tl = gsap.timeline();
	the_process_section_tl.from('.campaign-setup .the-process', 2.5, {
			clipPath: 'inset(200% -1% -1% -1%)',
			y: -200
		})
		.from('.campaign-setup .campaign-setup__title', 3, {
			clipPath: 'inset(-1% -1% 100% -1%)'
		})
		.from('.campaign-setup .campaign-setup__title span', 3, {
			clipPath: 'inset(-1% -1% 100% -1%)'
		}, '-2')
		.from('.campaign-setup__wrapper-left .list-item', 3, {
			xPercent: 10,
			stagger: 0.5
		},'-=5')
	ScrollTrigger.create({
		animation: the_process_section_tl,
		trigger: ".campaign-setup",
		start: "top +=100%",
		end: "+=40% +=90%",
		pin: false,
		markers: false,
		scrub: 1,
	});

	let the_process_section_images_tl = gsap.timeline();
	the_process_section_images_tl.from('.images-container-top-left', 0.5, {
			y: '10em'
		}, '-=0.5')
		.to('.images-container-bottom-right', 0.5, {
			y: '8em'
		}, '-=0.5')
	ScrollTrigger.create({
		animation: the_process_section_images_tl,
		trigger: ".campaign-setup .images-container",
		start: "top +=80%",
		end: "+=90% +=90%",
		pin: false,
		markers: false,
		scrub: 1,
	});
}

export default campaignSetup;