import freeEmailDomains from "./freeDomains";
import { validateMsgs } from "./validateMsgs";
import CryptoJS from './../crypto-js';

const demoRequestForm = _ =>{

	$("#demoRequest").validate({
		rules: {
			first_name: {
				alphanumeric: true,
				minlength: 3,
				maxlength: 30,
				required: true
			},
			last_name: {
				alphanumeric: true,
				maxlength: 30,
				minlength: 3,
				required: true
			},
			your_email: {
				required: true
			},
			business_name: {
				required: true
			}
		}
	});

    validateMsgs();
}

const contactUsForm = _ => {
	$("#contactUs").validate({
		rules: {
			first_name: {
				alphanumeric: true,
				minlength: 3,
				maxlength: 30,
				required: true
			},
			last_name: {
				alphanumeric: true,
				maxlength: 30,
				minlength: 3,
				required: true
			},
			your_email: {
				required: true
			},
			business_name: {
				required: true
			},
			user_message: {
				required: true
			}
		}
	});
    validateMsgs();

}





const cryptoForm = _ => {
    
	var appURL = (document.location.href.indexOf('localhost') > -1 || document.location.href.indexOf('file:///') > -1) ? 'http://localhost:3000/' : 'https://app.catalytics.io/';

	function validateEmail(email) {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (!re.test(String(email).toLowerCase())) {
			return false;
		}
		return true;
	}

	function isCorporateEmail(email) {
		var domainPieces = email.split('@');
		var domain = domainPieces[1];
		var freeEmailDomainsList = freeEmailDomains;
		if (freeEmailDomainsList.includes(domain)) {
			return false;
		}
		return true;
	}
	var log = document.getElementById('email_campaign__input');
	document.addEventListener('keydown', resetEmailSubmitAlert);

	function resetEmailSubmitAlert(e) {
		var input = document.getElementById('email_campaign__input');
		var inputError = document.getElementById('campaign-input-error');
		input.classList.remove('alert');
		inputError.innerHTML = '';
	}

	function submitEmailToCatalytics() {
		var input = document.getElementById('email_campaign__input');
		var inputError = document.getElementById('campaign-input-error');
		var email = input.value;4
		if (email === '') {
			input.classList.add('alert');
			inputError.innerHTML = 'Please enter a valid corporate email';
			return false;
		}
		if (!validateEmail(email)) {
			input.classList.add('alert');
			inputError.innerHTML = 'Please enter a valid corporate email';
			return false;
		}
		if (!isCorporateEmail(email)) {
			input.classList.add('alert');
			inputError.innerHTML = 'Please enter a valid corporate email';
			return false;
		}
		let key = '2e35'
		let emailStrEncrypted = CryptoJS.AES.encrypt(email, key)
		document.location.href = appURL + 'start-trial/' + encodeURIComponent(emailStrEncrypted)
		return false;
	}
	document.getElementById('submit-build-campaign-button').addEventListener("click", submitEmailToCatalytics);
	
} 


export {cryptoForm,demoRequestForm, contactUsForm}