const headerAnimation = _ => {
    const plat_header_tl = gsap.timeline();

    plat_header_tl
        .fromTo('.header_platform .top-line .border', 0.5, {
            width: '0%'
        }, {
            width: '78%'
        }, '+=0.1')
        .from('.header_platform-txt', 0.5, {
            y: 50,
            clipPath: 'inset(-1% -1% 100% -1%)'
        }, '-=0.2')
        .from('.header_platform__text-container', 0.5, {
            y: '10rem'
        }, '-=0.7')

    if (document.body.classList.contains('creative_page')) {
        plat_header_tl.from('.header_platform__pattern_img', 2.5, {
            scaleY: '0',
            ease: "expo.out"
        }, '-=0.7')
    } else {
        plat_header_tl.from('.header_platform__pattern_img', 2.5, {
            y: '-20rem',
            ease: "expo.out"
        }, '-=0.7')
    }



    plat_header_tl.from('.header_platform__visual img:nth-child(1)', 1, {
            y: '15rem'
        }, '-=3')
        .fromTo('.header_platform__visual img:nth-child(2)', 1.6, {
            y: '-30rem'
        }, {
            y: 0
        }, '-=3')
        .to('.header_platform-leftBar .border', 1.3, {
            height: '100%'
        }, '-=2.5')

    const plat_header_tl_scroll = gsap.timeline({
        scrollTrigger: {
            trigger: '.header_platform-wrapper--container',
            start: 'top top',
            end: 'bottom +=70%',
            markers: false,
            scrub: 0.01,
            pin: false,
        }
    });
    plat_header_tl_scroll.to('.header_platform .title--huge', 1, {
            yPercent: 50
        })
        .to('.header_platform__visual img:nth-child(1)', 2, {
            y: 100
        }, '-=1')
        .to('.header_platform__visual img:nth-child(2)', 2, {
            y: 130
        }, '-=2')

    return plat_header_tl
}

export {headerAnimation}