
const planetEarth = _ => {
    const planet_text_block_Animation = gsap.timeline({
		scrollTrigger: {
			trigger: '.planet_earth',
			start: 'top +=40%',
			end: '+=50%',
			markers: false,
			scrub: true,
			pin: false,
		}
	});
	
	planet_text_block_Animation.from('.planet_earth .planet_text_block .title--lg', 2, {
			clipPath: 'inset(200% -1% -1% -1%)'
		})
	
		
		if(document.body.classList.contains('creative_page')){
			planet_text_block_Animation.from('.planet_earth .planet_text_block li', 2, {
				opacity: 0,
				stagger:0.1
			})
		}else{
			planet_text_block_Animation.from('.planet_earth .planet_text_block .title--xsm', 2, {
				opacity: 0
			})
		}

		planet_text_block_Animation.from('.planet_text_block .border', 2, {
			width: 0
		})
		.from('.planet_earth .planet_text_block .description', 2, {
			opacity: 0
		})
		.fromTo('.planet_earth .floated-title', 4.3, {
			rotate: '-90deg',
			y: 800,

		}, {
			y: 100,
		});

}

export default planetEarth;