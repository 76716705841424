import campaignSetup from "../components/campaign_setup";
import showcase from "../components/showcase";

const aboutSection = _ => {

	ScrollTrigger.matchMedia({
		"(min-width: 577px)": function () {
			const masterTL = gsap.timeline({
				scrollTrigger: {
					trigger: "#about",
					start: "center center",
					end: "+=3000",
					scrub: true,
					pin: true,
					fastScrollEnd: false
				}
			});
			masterTL
				.to('#city-element', {
					y: '100%',
					scale: 1.5,
					duration: 100
				})
				.to('#squares', {
					y: '45%',
					duration: 100
				}, 0)
				.add(scene1Desktop(), 0)
				.add(scene2Desktop(), "-=50") // overlap slightly
		},
		"(max-width: 576px)": function () {
			const masterTL = gsap.timeline({
				scrollTrigger: {
					trigger: "#about",
					start: "center center",
					end: "+=3000",
					scrub: true,
					pin: true,
					fastScrollEnd: true
				}
			});
			masterTL
				.to('#city-element', {
					y: '100%',
					scale: 1.5,
					duration: 100
				})
				.to('#squares', {
					y: '45%',
					duration: 100
				}, 0)
				.add(scene1Mobile(), 0)
				.add(scene2Mobile(), "-=50") // overlap slightly
		}
	})

	function scene1Desktop() {
		let tl = gsap.timeline()
			.to('.content-reveal-out', {
				opacity: 0,
				duration: 10
			}, '+=10')
			// .to('.content-expand', {
			// 	clipPath: 'polygon(0 0%, 100% 0%, 100% 100%, 0% 100%)',
			// 	height: aboutImgHeight,
			// 	duration: 10,
			// }, '-=10')
		return tl;
	}

	function scene2Desktop() {

		let contentTitle = document.querySelectorAll('.heading-title')
		let contentDesc = document.querySelectorAll('.about .description')
		// let contentImg = document.querySelectorAll('.about__bottom-section__content-image')
		let tl = gsap.timeline()


		tl
			.to('.subHeading-wrapper .sub-heading-content', {
				y: '-70%',
				duration: 10
			}, '-=20')
			.to('.heading-wrapper .scene-1', {
				y: '200%',
				duration: 10
			}, '-=20')
			.to('.heading-wrapper .scene-2', {
				y: '0%',
				duration: 10
			}, '-=10')
			.to(contentTitle, {
				y: '-100%',
				duration: 10
			}, '-1')
			.to(contentDesc, {
				opacity: 0,
				duration: 10,
				onComplete: () => {
					document.querySelector('.about__bottom-section__container').classList.add('hide')
					document.querySelector('.about__article').classList.remove('hide')
				}
			}, '-1')
			.to('.about__article .divider', {
				scaleX: 1,
				transformOrigin: 'top left',
				duration: 10,
				stagger: 10,
				onReverseComplete: () => {
					document.querySelector('.about__bottom-section__container').classList.remove('hide')
					document.querySelector('.about__article').classList.add('hide')
				}
			}, '+10')
			.to('.about__article-title', {
				y: 0,
				duration: 10,
				stagger: 10,
				delay: -3
			}, '+10')
			.to('.about__article-desc', {
				opacity: 1,
				duration: 10,
				stagger: 10,
			}, '+10')
		return tl;
	}

	function scene1Mobile() {
		const content = document.querySelectorAll('.about__bottom-section__content')
		const contentImg = document.querySelectorAll('.content-expand')
		let contentTitle = document.querySelectorAll('.heading-title')
		let contentDesc = document.querySelectorAll('.about .description')
		let contentImgWrapper = document.querySelectorAll('.about__bottom-section__content-image')
		let tl = gsap.timeline()
			.set(content[1], {
				opacity: 0
			})
			.to('.content-reveal-out', {
				opacity: 0,
				duration: 10
			}, '+=10')
			// .to(contentImg[0], {
			// 	clipPath: 'polygon(0 0%, 100% 0%, 100% 100%, 0% 100%)',
			// 	height: aboutImgHeight,
			// 	duration: 10,
			// }, '-=10')
			.to(contentTitle[0], {
				y: '-100%',
				duration: 10
			}, '+=5')
			.to(contentDesc[1], {
				opacity: 0,
				duration: 10
			}, '-=10')
			.to(contentImgWrapper[0], {
				opacity: 0,
				duration: 10,
			}, '-=10')
			.to(content[1], {
				opacity: 1
			}, '-=1')
			// .to(contentImg[1], {
			// 	clipPath: 'polygon(0 0%, 100% 0%, 100% 100%, 0% 100%)',
			// 	height: aboutImgHeight,
			// 	duration: 10,
			// }, '+=1')
			.to(contentTitle[1], {
				y: '-100%',
				duration: 10
			}, '+=5')
			.to(contentDesc[1], {
				opacity: 0,
				duration: 10,
				onComplete: () => {
					document.querySelector('.about__bottom-section__container').classList.add('hide')
					document.querySelector('.about__article').classList.remove('hide')
				}
			}, '-=10')

		return tl;
	}

	function scene2Mobile() {
		let tl = gsap.timeline()
		const divider = document.querySelectorAll('.about__article .divider')
		const article = document.querySelectorAll('.about__article-card')
		const articleTitle = document.querySelectorAll('.about__article-title')
		const articleDesc = document.querySelectorAll('.about__article-desc')
		const articleImg = document.querySelectorAll('.about__article-img')

		tl
			.to('.subHeading-wrapper .sub-heading-content', {
				y: '-70%',
				duration: 10
			}, '-=20')
			.to('.heading-wrapper .scene-1', {
				y: '200%',
				duration: 10
			}, '-=20')
			.to('.heading-wrapper .scene-2', {
				y: '0%',
				duration: 10
			}, '-=10')

			.to(divider[0], {
				scaleX: 1,
				transformOrigin: 'top left',
				duration: 10,
				onReverseComplete: () => {
					document.querySelector('.about__bottom-section__container').classList.remove('hide')
					document.querySelector('.about__article').classList.add('hide')
				}
			})
			.to(articleTitle[0], {
				y: 0,
				duration: 10,
				delay: -3
			})
			.to(articleDesc[0], {
				opacity: 1,
				duration: 10,
				onComplete: () => {
					gsap.to(article[0], {
						opacity: 0,
						delay: .2
					})
				}
			})
			.to(divider[1], {
				scaleX: 1,
				transformOrigin: 'top left',
				duration: 10,
				onReverseComplete: () => {
					gsap.to(article[0], {
						opacity: 1,
						delay: .2
					})
				}
			})
			.to(articleTitle[1], {
				y: 0,
				duration: 10,
				delay: -3
			})
			.to(articleDesc[1], {
				opacity: 1,
				duration: 10,
				onComplete: () => {
					gsap.to(article[1], {
						opacity: 0,
						delay: .2
					})
				}
			})
			.to(divider[2], {
				scaleX: 1,
				transformOrigin: 'top left',
				duration: 10,
				onReverseComplete: () => {
					gsap.to(article[1], {
						opacity: 1,
						delay: .2
					})
				}
			})
			.to(articleTitle[2], {
				y: 0,
				duration: 10,
				delay: -3
			})
			.to(articleDesc[2], {
				opacity: 1,
				duration: 10,
			})
		return tl;
	}

}

const generalAnimations = _ => {

	var elmnt = document.querySelector('.homepage .campaign-setup__wrapper-left .list .try-demo')
	elmnt.addEventListener('click', function (e) {
		e.preventDefault()
		document.getElementById('form').scrollIntoView();
	})

	// ******************  Start THE PROCESS Animation
	let headerText = gsap.timeline();
	headerText.to('#header-text-box', 0.5, {
		opacity: 0.2
	}).to('#header-text-box .heading-xl', 1, {
		y: '-10rem',
		ease: Linear.ease
	}).to('#header-text-box .text-data', 1, {
		y: '-10rem',
		ease: Linear.ease
	}, '-=1')
	ScrollTrigger.create({
		animation: headerText,
		trigger: "#header-text-box",
		start: "top",
		end: "+=95% +=55%",
		pin: false,
		markers: false,
		scrub: 0.1,
	});
	// ABOUT Section 
	// let aboutImgHeight = getComputedStyle(document.querySelector('.about__bottom-section__content-image'))
	// 	.getPropertyValue('--content-image-height');


	// ******************  Start THE PROCESS Animation
	campaignSetup()
	// --------------------------------------------------------------
	// ******************  START SHOWCASE CATALYTICS Animation
	showcase()
	// --------------------------------------------------------------
	// ******************  START PRICING Animation
	let pricing_tl = gsap.timeline();
	pricing_tl.from('.pricing .title--lg', 3, {
		clipPath: 'inset(-1% -1% 100% -1%)',
	})
	ScrollTrigger.create({
		animation: pricing_tl,
		trigger: '.pricing .title--lg',
		start: 'top +=80%',
		end: '+=85% +=70%',
		pin: false,
		markers: false,
		scrub: 0.1,
	})
}

export {aboutSection, generalAnimations};