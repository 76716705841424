import campaignSetup from "../animations/components/campaign_setup";
import { headerAnimation } from "../animations/components/headerAnimation";
import showcase from "../animations/components/showcase";
import './../crypto-js';
import { cryptoForm } from "../components/forms";

const easiest_awareness = _ => {
    showcase();
    campaignSetup();
    headerAnimation();
    cryptoForm()
}


export default easiest_awareness;