const choosePlan = _ =>{
		let inquire_plan = document.querySelectorAll('.inquire-plan');
		let choosed_plan_input = document.getElementById('choosed_plan_input');


		for(let i=0; i<inquire_plan.length;i++){
			inquire_plan[i].addEventListener('click',function(){
				choosed_plan_input.style.display = 'block'
				let getPlanName = this.getAttribute('data-plan');
				choosed_plan_input.value = getPlanName;
				choosed_plan_input.setAttribute('value',getPlanName);
			})
		}
}

export default choosePlan