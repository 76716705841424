

const accordionFAQ = _ => {


    let faq_item_header = document.querySelectorAll('.faq-item')
	for (let i = 0; i < faq_item_header.length; i++) {
		faq_item_header[i].childNodes[1].childNodes[1].addEventListener('click', function () {
			faq_item_header[i].classList.toggle("active")
		})
		faq_item_header[i].childNodes[1].childNodes[3].addEventListener('click', function () {
			faq_item_header[i].classList.toggle("active")
		})
	}

}


export default accordionFAQ;